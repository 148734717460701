import React, { useState } from 'react';
import WindyMap from './map/map';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Box,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  SvgIcon,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import json__3 from './layers/_3';
import json__4 from './layers/_4';
import json___5 from "./layers/__5";
import json__6 from './layers/_6';
import json__10 from './layers/_10';
import json__11 from './layers/_11';
import json__12 from './layers/_12';
import json__13 from './layers/_13';
import json__14 from './layers/_14';
import json__15 from './layers/_15';
import json__16 from './layers/_16';
import json___17 from './layers/__17';
import json___18 from './layers/__18';
import json___19 from './layers/__19';
import json__20 from './layers/_20';
import json__Area_7 from './layers/_Area_7';
import json__Area_9 from './layers/_Area_9';
import json__Line_8 from './layers/_Line_8';
import json_CoalGaspowerplant_21 from './layers/CoalGaspowerplant_21';

const drawerWidth = 240;

function App() {
  const [geoJsonLayers, setGeoJsonLayers] = useState([
    { id: 1, name: "3", data: json__3, visible: false ,color:'#F7CAC9'},
    { id: 2, name: "대한민국영해직선기선도", data: json__4, visible: true , color:'#ff7800'},
    { id: 3, name: "5", data: json___5, visible: false ,color:'#34568B'},
    { id: 4, name: "한중잠정조치수역", data: json__6, visible: false ,color:'#F7CAC9'},
    { id: 5, name: "10", data: json__10, visible: false ,color:'#ffb766'},
    { id: 6, name: "11", data: json__11, visible: false ,color:'#fff685'},
    { id: 7, name: "12", data: json__12, visible: false ,color:'#f75990'},
    { id: 8, name: "13", data: json__13, visible: false ,color:'#ff0028'},
    { id: 9, name: "14", data: json__14, visible: false ,color:'#fff5d7'},
    { id: 10, name: "15", data: json__15, visible: false ,color:'#7c677f'},
    { id: 11, name: "16", data: json__16, visible: false ,color:'#f95d9b'},
    { id: 12, name: "도선사__17", data: json___17, visible: false ,color:'#9df9ef'},
    { id: 13, name: "도선사__18", data: json___18, visible: false ,color:'#6B5B95'},
    { id: 14, name: "19", data: json___19, visible: false ,color:'#82716e'},
    { id: 15, name: "20", data: json__20, visible: false ,color:'#f7f7f7'},
    { id: 16, name: "Area 7", data: json__Area_7, visible: false ,color:'#94f0f1'},
    { id: 17, name: "Area 9", data: json__Area_9, visible: false ,color:'#c2b490'},
    { id: 18, name: "Line 8", data: json__Line_8, visible: false ,color:'#4f5f76'},
    { id: 19, name: "석탄화력발전소", data: json_CoalGaspowerplant_21, visible: false ,color:'black'},
  ]);

  const toggleLayerVisibility = id => {
    setGeoJsonLayers(layers =>
      layers.map(layer =>
        layer.id === id ? { ...layer, visible: !layer.visible } : layer
      )
    );
  };

  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = () => setDrawerOpen(!drawerOpen);

  return (
    <div className="App">
      <AppBar variant='dense' position="fixed" sx={{ backgroundColor:"#03172B",width: drawerOpen ? `calc(100% - ${drawerWidth}px)` : '100%', ml: drawerOpen ? `${drawerWidth}px` : 0 }}>
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer}
            sx={{ mr: 2, ...(drawerOpen && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <img src='/images/DTS logo.png'width={200} />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        open={drawerOpen}
        onClose={toggleDrawer}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', height: 748 },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            {geoJsonLayers.map(layer => {
              const CustomUncheckedIcon = () => (
                <SvgIcon viewBox="0 0 24 24">
                  <rect width="24" height="24" rx="4" fill={layer.color} />
                </SvgIcon>
              );

              const CustomCheckedIcon = () => (
                <SvgIcon viewBox="0 0 24 24">
                  <rect width="24" height="24" rx="4" fill={layer.color} />
                  <path d="M19 7L10 16L5 11L6.5 9.5L10 13L17.5 5.5L19 7Z" fill="white" />
                </SvgIcon>
              );

              return (
                <ListItem button key={layer.id} onClick={() => toggleLayerVisibility(layer.id)} style={{ paddingTop: 0, paddingBottom: 0, margin: 0 }}>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={layer.visible}
                      tabIndex={-1}
                      disableRipple
                      icon={<CustomUncheckedIcon />}
                      checkedIcon={<CustomCheckedIcon />}
                    />
                  </ListItemIcon>
                  <ListItemText     primaryTypographyProps={{
                    style: {
                      fontSize:13,
                      fontWeight: layer.visible ? 'bold' : 'normal'
                    }
                  }} primary={layer.name} style={{ lineHeight: 1, margin: 0 }} />
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Drawer>

      <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 0, ml: drawerOpen ? `${drawerWidth}px` : 0 }}>
        <Toolbar />
        <WindyMap geoJsonLayers={geoJsonLayers} />
      </Box>
    </div>
  );
}

export default App;
