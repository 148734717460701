import React, { useEffect, useRef } from 'react';


const WindyMap = ({ geoJsonLayers }) => {
  const mapRef = useRef(null);
  const layersRef = useRef([]);

  useEffect(() => {
    const options = {
      key: 'H4DiHlvuSblUA2xn83Z8Z4NQg09Nfhol',
      verbose: true,
      lat: 36.3504119,
      lon: 127.3845475,
      zoom: 7,
    };

    // Initialize Windy API
    window.windyInit(options, (windyAPI) => {
      const { map, store, overlays } = windyAPI;
      const L = window.L
      mapRef.current = map;

      // Initialize all geoJson layers
      geoJsonLayers.forEach(layer => {
        const geoJsonLayer = L.geoJSON(layer.data, {
          style: { color: layer.color, weight: layer.id===19?8: 2, opacity: 0.8 },

          onEachFeature: (feature, layer) => {
            layer.on('click', () => {
              L.popup()
                .setLatLng(layer.getBounds().getCenter())
                .setContent(feature.properties.name || 'No Name')
                .openOn(map);
            });
          },

        });

        layersRef.current[layer.id] = geoJsonLayer;
        if (layer.visible) geoJsonLayer.addTo(map);
      });

      store.set('overlay', 'wind');
    });

    return () => {
      Object.values(layersRef.current).forEach(layer => layer && layer.remove());
    };
  }, [geoJsonLayers]);

   return (
    <div id="windy" style={{ width: '100%', height: '700px' }}>
      <div id="windy-map" />
    </div>
  );
};

export default WindyMap;
